export default function WallSelection({ activeWall, setActiveWall }) {
  return (
    <div className="w-1/3 grid grid-cols-2 gap-4 text-lg pb-4">
      <button
        className={
          "px-12 pt-3 pb-2 border-8 " +
          (activeWall == 0 ? "border-solid" : "border-hidden")
        }
        onClick={() => setActiveWall(0)}
      >
        LINKE WAND
      </button>
      <button
        className={
          "px-12 pt-3 pb-2 border-8 " +
          (activeWall == 1 ? "border-solid" : "border-hidden")
        }
        onClick={() => setActiveWall(1)}
      >
        RECHTE WAND
      </button>
    </div>
  );
}
