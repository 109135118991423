import { useEffect, useRef, useState } from "react";
import { getJson } from "../Helper/ImportFromWeb";
import ListItem from "./ListItem";

export default function ObjectList({
  click,
  openmodal,
  selectedObj,
  activeWall,
}) {
  const [data, setData] = useState([]);
  const [setzkastenNummern, setSetzkastenNummern] = useState([1, 2]);
  const scrollableDivRef = useRef(null);

  const selectedObjRef = useRef();
  const stylingAddon = openmodal
    ? "grid-cols-6 w-1/2 pr-2"
    : "grid-cols-6 w-full";

  useEffect(() => {
    const bla = async () => {
      const newData = await getJson();
      const searchParam = new URLSearchParams(document.location.search);
      if (searchParam.get("wall")) {
        const nummer = parseInt(searchParam.get("wall"));
        if (!isNaN(nummer)) {
          setSetzkastenNummern([nummer, nummer + 1]);
          console.log("wallName set To: ", nummer);
        }
      }
      setData(newData);
    };
    bla();
  }, []);

  useEffect(() => {
    selectedObjRef?.current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
    if (!openmodal) {
      selectedObjRef.current = null;
    }
  }, [openmodal, selectedObjRef]);

  useEffect(() => {
    if (scrollableDivRef.current) {
      scrollableDivRef.current.scrollTop = 0;
    }
    selectedObjRef.current = null;
  }, [activeWall]);

  const getObjectProps = (obj) => {
    const itemsWithSameImg = data.filter(
      (x) =>
        x.SetzkastenNr == setzkastenNummern[activeWall] &&
        x.DateinameBild &&
        x.DateinameBild == obj.DateinameBild
    );
    if (itemsWithSameImg.length > 1) {
      if (
        itemsWithSameImg.some(
          (x) =>
            parseInt(x.SetzkastenPosition) < parseInt(obj.SetzkastenPosition)
        )
      ) {
        return [undefined, "", 1, 1];
      }
      const positions = itemsWithSameImg
        .map((item) => item.SetzkastenPosition)
        .join(", ");
      console.log("bla itemswithsame: ", parseInt(obj.SetzkastenPosition) + 6);
      const rowSize = itemsWithSameImg.filter(
        (x) => x.SetzkastenPosition % 6 == parseInt(obj.SetzkastenPosition) % 6
      ).length;
      const colSize = itemsWithSameImg.filter(
        (x) =>
          x.SetzkastenPosition <=
          parseInt(obj.SetzkastenPosition) +
            (6 - (parseInt(obj.SetzkastenPosition) % 6))
      ).length;
      console.log("bla multi: ", rowSize, colSize);
      return [true, positions, rowSize, colSize];
    } else {
      return [true, obj.SetzkastenPosition, 1, 1];
    }
  };

  return (
    <ul
      className={
        "grid " +
        stylingAddon +
        " gap-4 place-items-center overflow-y-scroll overflow-x-hidden flex "
      }
      ref={scrollableDivRef}
    >
      {data
        .filter((x) => x.SetzkastenNr == setzkastenNummern[activeWall])
        .map((obj) => {
          const [show, positions, rowSize, colSize] = getObjectProps(obj);
          return (
            show && (
              <ListItem
                key={`${obj.SetzkastenNr}-${obj.SetzkastenPosition}`}
                item={obj}
                selectedObj={selectedObj}
                selectedObjRef={selectedObjRef}
                openmodal={openmodal}
                click={click}
                positions={positions}
                rowSize={rowSize}
                colSize={colSize}
              />
            )
          );
        })}
    </ul>
  );
}
