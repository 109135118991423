import { IoCloseOutline } from "react-icons/io5";
import { BiZoomIn } from "react-icons/bi";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import { useEffect, useState } from "react";
import { baseFolder } from "../Helper/ImportFromWeb";

export default function DetailView({ selectedObj, closeModal }) {
  const [showZoomHint, setShowZoomHint] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const [delay, setDelay] = useState(false);
  //console.log("selectedObj ", selectedObj)

  useEffect(() => {
    // needed to refresh InnerImageZoom component after object changed
    resetImageZoom();
  }, [selectedObj]);

  function OneInfoElement({ desc, content, fontStyle = "defaultfont" }) {
    return content !== "" ? (
      <div className="flex py-2 ">
        {desc}
        <div className={" pl-1 " + fontStyle}>{content}</div>
      </div>
    ) : (
      ""
    );
  }

  const resetImageZoom = () => {
    setIsZoomed(false);
    setDelay(true);
    setTimeout(() => setDelay(false), 1);
  };

  const onZoomIn = (e) => {
    setIsZoomed(true);
    setShowZoomHint(false);
  };

  const onZoomOut = (e) => {
    setIsZoomed(false);
  };

  const onZoomIconClick = (e) => {
    console.log("ok zoom clicked");
    setShowZoomHint(true);
    e.preventDefault();
  };

  return (
    <div className="bg-white border-l-8 overflow-scroll relative w-1/2">
      <div className="sticky top-0 text-4xl cursor-pointer">
        <IoCloseOutline onClick={() => closeModal(selectedObj)} />
      </div>
      <div className="flex w-full place-items-center">
        <div className="pl-10 text-lg font-light w-1/2">
          <OneInfoElement
            desc="Setzkasten Nr. "
            content={selectedObj.SetzkastenNr}
          />
          <OneInfoElement
            desc="Setzkasten Position "
            content={selectedObj.SetzkastenPosition}
          />
          <OneInfoElement desc="Person: " content={selectedObj.Person} />
          <OneInfoElement desc="Alter: " content={selectedObj.Alter} />
          <OneInfoElement desc="Ort: " content={selectedObj.Wo} />
        </div>
        <div className="relative w-1/2 h-1/4 pl-2">
          {!delay ? (
            <InnerImageZoom
              src={baseFolder + selectedObj.DateinameBild}
              hideHint={true}
              afterZoomIn={onZoomIn}
              afterZoomOut={onZoomOut}
              hideCloseButton={true}
            />
          ) : (
            <img
              className="m-1"
              src={baseFolder + selectedObj.DateinameBild}
              alt="objekt"
            />
          )}

          {!isZoomed && (
            <BiZoomIn
              className="absolute bottom-0 top-auto left-auto right-0 text-2xl bg-white"
              onClick={onZoomIconClick}
            />
          )}
          {isZoomed && (
            <IoCloseOutline
              className="absolute bottom-auto top-0 left-auto right-0 text-2xl bg-white"
              onClick={resetImageZoom}
            />
          )}

          {showZoomHint && (
            <div className="absolute bottom-0 top-auto left-0 right-auto text-sm font-light bg-white px-1 pt-1">
              Klicke zum Vergrössern direkt auf das Bild
            </div>
          )}
        </div>
      </div>
      {selectedObj.Titel && (
        <div className="pl-10 pt-8 text-2xl font-light ">
          {selectedObj.Titel}
        </div>
      )}
      <div className="pl-10 text-lg font-light">
        {selectedObj.Text !== "" ? (
          <div className="py-4">
            {selectedObj.Text.split("\n").map((line, index) => (
              <p className="pb-4" key={"detailP" + index}>
                {line}
              </p>
            ))}
          </div>
        ) : (
          ""
        )}
        {selectedObj.DateiNameZusatzBild &&
          selectedObj.DateiNameZusatzBild.split(",").map((x, i) => (
            <img
              key={`zusatzbild-${i}`}
              src={baseFolder + x}
              alt="zusatzbild"
              className="w-64 pb-4"
            />
          ))}
      </div>
    </div>
  );
}
