import { createRef } from "react";
import { baseFolder } from "../Helper/ImportFromWeb";

export default function ListItem({
  item,
  selectedObjRef,
  openmodal,
  selectedObj,
  click,
  positions,
  rowSize,
  colSize,
}) {
  const ref = createRef();

  const colSpan =
    colSize == 2
      ? "col-span-2"
      : colSize == 3
      ? "col-span-3"
      : colSize == 4
      ? "col-span-4"
      : colSize == 5
      ? "col-span-5"
      : colSize == 6
      ? "col-span-6"
      : "col-span-1";
  const rowSpan =
    rowSize == 2
      ? "h-96 row-span-2"
      : rowSize == 3
      ? "h-[37rem] row-span-3"
      : rowSize == 4
      ? "h-[49rem] row-span-4"
      : "h-48 row-span-1";
  const imgMaxHeight =
    rowSize == 2
      ? "max-h-96"
      : rowSize == 3
      ? "max-h-[37rem]"
      : rowSize == 4
      ? "max-h-[49rem]"
      : "max-h-48";

  const handleClick = () => {
    selectedObjRef.current = ref.current;
  };
  return (
    <li
      key={`el-${item.SetzkastenNr}-${item.SetzkastenPosition}`}
      ref={ref}
      className={`p-1 relative flex justify-center items-center ${rowSpan} ${colSpan}`}
    >
      <button
        className={
          " cursor-pointer transition transform hover:scale-110" +
          (openmodal && selectedObj === item ? "border-8 " : "")
        }
        onClick={() => {
          click(item);
          handleClick(ref);
        }}
        key={item.DateinameBild}
      >
        <img
          alt={item.DateinameBild}
          className={`w-full h-auto ${imgMaxHeight}`}
          src={baseFolder + item.DateinameBild}
        />
      </button>
      <div
        className={`absolute inset-x-0 pointer-events-none ${
          rowSize > 1 ? "bottom-0 " : "bottom-2 "
        }`}
      >
        <p className="text-center text-base">
          <span className="bg-white px-1 pt-1">{positions}</span>
        </p>
      </div>
    </li>
  );
}
