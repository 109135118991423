import { useState } from "react";
import DetailView from "../components/DetailView";
import ObjectList from "../components/ObjectList";
import WallSelection from "../components/WallSelection";

export default function Objects() {
  const [openmodal, setOpenmodal] = useState(false);
  const [detailViewImage, setDetailViewImage] = useState(false);
  const [selectedObj, setSelectedObj] = useState(false);
  const [activeWall, setActiveWall] = useState(0);

  const closeModal = (obj) => {
    (obj.DateinameBild === detailViewImage || !detailViewImage || !openmodal) &&
      setOpenmodal(!openmodal);
    setDetailViewImage(obj.DateinameBild);
    setSelectedObj(obj);
  };

  const changeWall = (wall) => {
    if (wall !== activeWall) {
      setActiveWall(wall);
      setOpenmodal(false);
      setSelectedObj(false);
    }
  };

  return (
    <div className="p-4 relative h-screen w-screen max-h-screen flex flex-col">
      {/*  <div className="text-3xl pb-6 w-full">Objektteppich</div> */}
      {/* <div className="py-6 w-full">Untertitel / Beschreibung</div>  */}
      <WallSelection
        activeWall={activeWall}
        setActiveWall={changeWall}
        resetOpenModal={() => {
          setOpenmodal(false);
          setSelectedObj(false);
        }}
      />
      <div className="flex overflow-y-scroll w-full">
        <ObjectList
          click={(obj) => {
            //   console.log("detailViewImage", detailViewImage)
            //   console.log("obj.DateinameBild", obj.DateinameBild)
            closeModal(obj);
          }}
          openmodal={openmodal}
          selectedObj={selectedObj}
          activeWall={activeWall}
        />
        {openmodal && selectedObj && (
          <DetailView selectedObj={selectedObj} closeModal={closeModal} />
        )}
      </div>
    </div>
  );
}
