export const baseFolder = "setzkasten/";
const csvData = "setzkasten/001_setzkasten_aarau.csv";
const csv = require("csvtojson");

export const getJson = async () => {
  const res = await fetch(csvData);
  const text = await res.text();
  const jsonArray = await csv().fromString(text);
  //console.log("bla got json array: ", jsonArray);
  return jsonArray;
};
